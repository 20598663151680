<template>
  <div class="common-bg">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <div class="detail">
      <div class="layout">
        <div class="add-type3" v-if="productType === '3'">
          <div class="type3-img">
            <van-image :src="data.images" />
          </div>
        </div>
      </div>
      <div class="title">{{ data.title }}</div>
      <div class="czf">
        <img src="@/assets/images/face1.png" alt="" />
        <div class="text">
          <div class="price">￥{{ data.now_price }}</div>
        </div>
      </div>
      <div class="padded-15 text-danger">
        <div class="btn padded-t-15">
          <van-button type="warning" block @click="onSubmit()"
            >立即购买</van-button
          >
        </div>
      </div>
    </div>
    <van-dialog
      v-model:show="showPay"
      title="选择支付方式"
      @confirm="showPay = false"
    >
      <van-radio-group v-model="checkPay">
        <van-radio
          v-for="(item, index) of dataPay"
          :key="item.value"
          class="pay"
          shape="square"
          :name="index"
          >{{ item.label }}</van-radio
        >
      </van-radio-group>
    </van-dialog>
  </div>
  <!-- <van-popup
    v-model:show="passwordShow"
    round
    position="bottom"
    :style="{ height: '380px', background: '#f2f3f5' }"
  >
    <div class="padded-15 text-center">输入支付密码</div>
    <van-password-input
      :value="payPassword"
      :gutter="10"
      info="密码为 6 位数字"
      :error-info="errorInfo"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
    />
    <van-number-keyboard v-model="payPassword" :show="showKeyboard" />
  </van-popup> -->
</template>

<script>
import {
  getProductDetail,
  checkPayPwdIsCorrect,
  submitOrder,
  submitOrder1,
} from "@/utils/api.js";
export default {
  name: "info",
  data() {
    return {
      productId: this.$route.query.id,
      productType: this.$route.query.type,
      title: "详情",
      step: 1,
      num: 1,
      min: 1,
      data: {},
      payPassword: "",
      errorInfo: "",
      showKeyboard: true,
      passwordShow: false,
      showPay: false,
      checkPay: 0,
      dataPay: [
        { label: "支付宝", value: "alipay" },
        { label: "微信", value: "weixin" },
      ],
    };
  },
  watch: {
    payPassword(value) {
      if (value.length > 6) {
        return;
      }
      if (value.length === 6) {
        this.$toast.loading({
          duration: 0,
          message: "提交中...",
          forbidClick: true,
        });
        setTimeout(() => {
          this.checkPayPwdIsCorrect();
        }, 1500);
      }
    },
  },
  created() {
    this.setTitle();
    this.getDetail();
  },
  computed: {
    totalPrice: function () {
      let price = 0;
      if (this.productType === "3") {
        price = this.num * this.data.price;
        return price.toFixed(2);
      } else {
        if (this.step === 1) {
          price = this.num * this.data.nowPrice;
          return price.toFixed(2);
        } else {
          price = this.num * this.data.nowPrice;
          return (price / 100).toFixed(2);
        }
      }
    },
    totalFenHong: function () {
      let fenhong = 0;
      //   if (this.step === 1) {
      fenhong = this.num * this.data.fenhong;
      return fenhong.toFixed(2);
      //   } else {
      //     fenhong = this.num * this.data.fenhong
      //     return (fenhong / 100).toFixed(2)
      //   }
    },
  },
  methods: {
    getDetail() {
      getProductDetail(this.productId).then((res) => {
        this.data = res.data;
        this.num = res.data.num;
        this.min = JSON.parse(JSON.stringify(res.data.num));
        this.step = 1;
      });
    },
    confirmPassword() {
      this.passwordShow = true;
    },
    // 检测支付密码是否正确
    checkPayPwdIsCorrect() {
      const param = { payPassword: this.payPassword };
      checkPayPwdIsCorrect(param).then((res) => {
        if (res.code === 200) {
          if (res.data === "true") {
            this.onSubmit();
          } else {
            this.payPassword = "";
            this.$toast(res.message);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    onSubmit() {
      if (this.productType === "3") {
        this.totalFenHong = 0;
      }
      const param = {
        productId: this.data.id,
        money: this.totalPrice,
        num: this.num,
        fenhong: this.totalFenHong,
        paytype: this.dataPay[this.checkPay].value,
      };
      this.$toast.loading({
        duration: 0,
        message: "提交中...",
        forbidClick: true,
      });
      setTimeout(() => {
        submitOrder(param).then((res) => {
          this.$toast.clear();
          if (res.code === 200) {
            this.$toast("购买成功");
            return;
            //     window.location.href = res.message
          } else {
            this.$toast(res.message);
            if (res.message === "请先实名") this.$router.push("/home/realName");
          }
        });
      }, 1700);
    },
    // 检测支付密码是否正确线上支付
    checkPayPwdIsCorrect() {
      const param = { payPassword: this.payPassword };
      checkPayPwdIsCorrect(param).then((res) => {
        if (res.code === 200) {
          if (res.data === "true") {
            this.onSubmit1();
          } else {
            this.payPassword = "";
            this.$toast(res.message);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    onSubmit1() {
      if (this.productType === "3") {
        this.totalFenHong = 0;
      }
      const param = {
        productId: this.data.id,
        money: this.totalPrice,
        num: this.num,
        fenhong: this.totalFenHong,
        paytype: this.dataPay[this.checkPay].value,
      };
      this.$toast.loading({
        duration: 0,
        message: "提交中...",
        forbidClick: true,
      });
      setTimeout(() => {
        submitOrder1(param).then((res) => {
          this.$toast.clear();
          if (res.code === 200) {
            window.location.href = res.message;
          } else {
            this.$toast(res.message);
            if (res.message === "请先实名") this.$router.push("/home/realName");
          }
        });
      }, 1700);
    },
    setTitle() {
      if (this.productType === 1) {
        this.title = "股权详情";
      } else if (this.productType === 2) {
        this.title = "基金详情";
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  .layout {
    background: #ffffff;
    margin: 15px;
    border-radius: 20px;
  }

  .add-type3 {
    display: flex;
    align-items: center;
    border-radius: 20px;
    .type3-img {
    }
  }
}
.pay {
  padding: 10px;
}
.title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.czf {
  display: flex;
  width: 90%;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  margin: auto;
}
.czf img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
.czf .text span {
  font-size: 10px;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  margin-left: 20px;
}
.del_img {
  width: 90%;
  height: 100%;
  background-color: #ffffff;
  align-items: center;
  margin: auto;
  margin-top: 20px;
}
.del_img img {
  border-radius: 10px;
}
.del {
  font-size: 20px;
  font-weight: bold;
  margin-left: 25px;
  margin-top: 20px;
}
.del_text .text span {
  display: flex;
  width: 80%;
  padding: 20px 20px 20px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  margin: auto;
}
.price {
  position: absolute;
  margin-left: 70px;
  z-index: 3;
  color: #000000;
  margin-top: -12px;
  font-weight: bold;
}
.info-box {
  width: 343px;
  background: #fff;
  box-shadow: 0 16px 40px -4px rgb(243 142 142 / 8%);
  border-radius: 8px;
  margin: 20px auto;
  .info-li {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .li-l {
      font-size: 14px;
      color: #999;
    }
    .li-r {
      display: flex;
      align-items: center;
    }
  }
}
</style>
